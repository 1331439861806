export const profiles = [
  {
    githubUrl: "https://github.com/MightyJoeW",
    githubUsername: "mightyjoew",
    linkedinUrl: "https://www.linkedin.com/in/josephmwarren/",
    photo: "https://avatars.githubusercontent.com/u/24509848?v=4",
    siteUrl: "https://www.getscriptordietryin.com/",
  },
  {
    githubUrl: "https://github.com/MrsWarren",
    githubUsername: "MrsWarren",
    linkedinUrl: "https://www.linkedin.com/in/krystleawarren/",
    photo: "https://avatars.githubusercontent.com/u/31019414?v=4",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/mariothedog",
    githubUsername: "Mariothedog",
    linkedinUrl: null,
    photo: "https://avatars.githubusercontent.com/u/28541089?v=4",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/nlewis84",
    githubUsername: "nlewis84",
    linkedinUrl: "https://www.linkedin.com/in/nlewis84",
    photo:
      "https://media-exp1.licdn.com/dms/image/C4E03AQF6GRty7hLmdA/profile-displayphoto-shrink_400_400/0/1619664151128?e=1639008000&v=beta&t=E5bVRZmjH3EH2fAKBdA3YQ-FHBYb_WHGdYv5tNM6iRo",
    siteUrl: "https://nathanlewis.dev",
  },
  {
    githubUrl: "https://github.com/Wijayaac",
    githubUsername: "Wijayaac",
    linkedinUrl: "https://www.linkedin.com/in/kadek-wijaya-217577212/",
    photo: "https://avatars.githubusercontent.com/u/60057324?v=4",
    siteUrl: "https://wijayaac.netlify.app/",
  },
  {
    githubUrl: "https://github.com/Yash-271120",
    githubUsername: "Yash-271120",
    linkedinUrl: "https://www.linkedin.com/in/yash-patil-3a163519b/",
    photo:
      "https://www.placement.iitbhu.ac.in/media/user_dp/2019/met/yash.ajitpatil.met19.png",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/agungjsp",
    githubUsername: "agungjsp",
    linkedinUrl: null,
    photo: "https://avatars.githubusercontent.com/u/30463526?v=4",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/rohitkothapalli",
    githubUsername: "rohitkothapalli",
    linkedinUrl: "https://www.linkedin.com/in/rohit-kothapalli-079719194/",
    photo: null,
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/riyakushwaha",
    githubUsername: "riyakushwaha",
    linkedinUrl: "https://www.linkedin.com/in/riyakushwaha",
    photo: null,
    siteUrl: "https://www.twitter.com/riyakushwaha20",
  },
  {
    githubUrl: "https://github.com/JT007-ctrl",
    githubUsername: "JT007-ctrl",
    linkedinUrl: "https://www.linkedin.com/in/joshua-adenusi-4a0ab9198/",
    photo:
      "https://lh3.googleusercontent.com/a-/AOh14GgEBsFeHdcbzuG5skCiGwnjYVlU6lIx520csbjVlg=s288-p-rw-no",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/aurora-0025",
    githubUsername: "aurora-0025",
    linkedinUrl: null,
    photo: "https://avatars.githubusercontent.com/u/78461388?v=4",
    siteUrl: "https://dragonacademia.tk/",
  },
  {
    githubUrl: "https://github.com/rriegel",
    githubUsername: "rriegel",
    linkedinUrl: "https://www.linkedin.com/in/ryanjriegel/",
    photo: "https://avatars.githubusercontent.com/u/75142382?v=4",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/SairamNomula",
    githubUsername: "Sairam Nomula",
    linkedinUrl: "https://www.linkedin.com/in/sairam-nomula-8aa752192/",
    photo: "https://i.ibb.co/KLWqCP5/Me.png",
    siteUrl: "https://sairamnomula.netlify.app/",
  },
  {
    githubUrl: "https://github.com/IniobongUdom7",
    githubUsername: "IniobongUdom7",
    linkedinUrl: "https://www.linkedin.com/in/iniobong-udom-20171a150/",
    photo: "https://avatars.githubusercontent.com/u/63455287?v=4",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/fullburnen",
    githubUsername: "fullburnen",
    linkedinUrl: null,
    photo: "https://avatars.githubusercontent.com/u/70560944",
    siteUrl: null,
  },
  {
    githubUrl: "https://github.com/angelamcosta",
    githubUsername: "angelamcosta",
    linkedinUrl: "https://www.linkedin.com/in/angelamcostalima/",
    photo: "https://avatars.githubusercontent.com/u/14792447?v=4",
    siteUrl: "https://angelamcosta.github.io/",
  },
  {
    githubUrl: "https://github.com/devcer",
    githubUsername: "devcer",
    linkedinUrl: "https://www.linkedin.com/in/isantoshv/",
    photo: "https://avatars.githubusercontent.com/u/3799600?v=4",
    siteUrl: "https://devcer.github.io/",
  },
  {
    githubUrl: 'https://github.com/bartek532',
    githubUsername: 'Bartek532',
    photo: 'https://avatars.githubusercontent.com/u/57185551?v=4',
    siteUrl: 'https://bartek532.github.io/portfolio',
  },
  {
    githubUrl: "https://github.com/erikweibust",
    githubUsername: "erikweibust",
    linkedinUrl: "https://www.linkedin.com/in/erikweibust/",
    photo: "https://avatars.githubusercontent.com/u/566877?v=4",
    siteUrl: "https://twitter.com/erikweibust"
  }
];
